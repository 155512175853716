<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                Fahrzeug Hinzufügen
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control form-control-sm" v-model="name"/>
                    </div>   
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group row">
                    <label class="col-md-3 col-form-label">Kennzeichen</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control form-control-sm" v-model="plate"/>
                    </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-right">
            <button class="btn btn-primary" @click="createCar" :disabled="name == '' || plate == '' ">
                <i class="fas fa-save"> Hinzufügen</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarsIndex',
    title: 'Farzeug hinzufügen',
    data() {
        return {
            name: '',
            plate: ''
        };
    },

    methods: {
        createCar()
        {
            this.axios
                .post('/cars', {
                    name: this.name,
                    plate: this.plate,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Fahrzeug erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$router.push({name: 'cars.index'});
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        }
    }
}
</script>

<style>

</style>